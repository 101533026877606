import MealPlan from './MealPlan';
import ScrollDownOnLoad from '../common/ScrollDownOnLoad';
import { useEffect, useState, useRef } from 'react';
import CookieConsent from '../cookie_consent/CookieConsent';

function Home() {
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const headingRef = useRef(null)

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent) {
            setCookiesAccepted(true);
        }
    }, []);

    return (
        <div className="App">
            <header className="App-header blend-into-image">
                <h1 className='gradient-text'>Rate My Meal</h1>
            </header>
            <main className='App-main'>
                <MealPlan headingRef={headingRef}/>
            </main>
            <ScrollDownOnLoad cookiesAccepted={cookiesAccepted} headingRef={headingRef} />
            <CookieConsent onAccept={() => setCookiesAccepted(true)} />
        </div>
    );
}

export default Home;