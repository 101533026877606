import React, { Component } from 'react';
import Rating from '@mui/material/Rating';
import { addRating, putData } from '../../backend/data';
import { Button } from '@mui/material';
import { RatingContext } from './RatingContext';
import { preconnect } from 'react-dom';
class RatingComponent extends Component {
  static contextType = RatingContext;

  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      tempRating: 0,
      isReadOnly: false,
      isButtonDisabled: false,
      previousRating: 0,
    };
  }

  componentDidMount() {
    this.checkRatingState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.triggerRerender !== undefined && prevState.triggerRerender !== this.context.triggerRerender) {
      this.checkRatingState();
    }
  }

  checkRatingState = () => {
    const currentDate = new Date().toDateString();
    const lastAccessDate = localStorage.getItem('lastAccessDate');

    if (lastAccessDate !== currentDate) {
      localStorage.setItem('hasRated', false);
      this.setState({
        isReadOnly: false,
        isButtonDisabled: false,
        tempRating: 0,
        rating: 0,
      });
    } else {
      // If the user has already rated today, retrieve the stored rating and set the state accordingly
      const mealId = this.props.name;
      const storedRating = parseFloat(localStorage.getItem(`rating_${mealId}`), 10);
      const hasRated = localStorage.getItem('hasRated') === 'true';

      this.setState({
        isReadOnly: hasRated, // Make the rating read-only if the user has already rated
        rating: storedRating || 0, // Set the stored rating or default to 0
        tempRating: storedRating || 0, // Set the temporary rating or default to 0
        isButtonDisabled: hasRated, // Disable the confirm button if the user has already rated
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const ratingData = {
      name: this.props.name,
      rating: this.state.rating
    };
    putData(ratingData.name, ratingData.rating)
      .then(() => {
        this.setState({ isReadOnly: true, isButtonDisabled: true }, () => {
          localStorage.setItem(`hasRated`, true);
          localStorage.setItem(`rating_${ratingData.name}`, this.state.rating);
          localStorage.setItem('lastAccessDate', new Date().toDateString());

          this.props.onSubmitRating(ratingData.rating);
          this.context.confirmRating();
        });
      });
    if (localStorage.getItem('isLoggedIn') === 'true') {
      addRating(localStorage.getItem('userEmail'), ratingData.name, ratingData.rating);
    }
  };

  handleRatingChange = (event, newValue) => {
    if (!this.state.isReadOnly) {
      console.log("Before: ", newValue, this.state.previousRating, this.state.tempRating);
      if (newValue === this.state.previousRating) {
        this.setState({ tempRating: 0, previousRating: this.state.tempRating });
        return;
      }
      if (newValue === null) {
        const newRating = this.state.tempRating === Math.floor(this.state.tempRating) ? this.state.tempRating - 0.5 : Math.floor(this.state.tempRating);
        this.setState({ tempRating: newRating, previousRating: this.state.tempRating });
      } else {
        this.setState({ tempRating: newValue, previousRating: this.state.tempRating });
      }
    }
  };

  handleConfirmRating = (event) => {
    console.log(this.state.tempRating);
    if (this.state.tempRating === 0 || this.state.tempRating === null) {
      return;
    }
    this.setState({ isButtonDisabled: true, rating: this.state.tempRating }, () => {
      this.handleSubmit(event);
    });
  }


  render() {
    const ratingStyles = {
      color: this.props.readonly ? 'darkorange' : 'orange',
    }
    return (
      <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', }}>
        <Rating
          name="simple-controlled"
          value={this.state.tempRating}
          size={this.props.size}
          precision={this.props.precision}
          onChange={this.handleRatingChange}
          style={ratingStyles}
          disabled={this.state.isReadOnly || this.context.hasRated}
        />
        {(!this.state.isReadOnly || !this.context.hasRated) && (
          <Button style={{ marginTop: '4%' }} variant='contained' sx={{
            color: '#282c34',
            backgroundColor: 'orange',
            height: '35px',
            fontSize: '0.8rem',
            fontFamily: 'Neue Regrade Bold',
            border: '2px solid orange',
            '&:hover': {
              backgroundColor: '#ed672a',
              border: '2px solid #ed672a'
            },
            '&.Mui-disabled': {
              backgroundColor: 'grey',
              border: '2px solid grey'
            }
          }}
            onClick={this.handleConfirmRating}
            disabled={this.state.isButtonDisabled || this.context.hasRated || this.state.tempRating === 0 || this.state.tempRating === null}
          >
            Confirm
          </Button>
        )}
      </div>
    );
  }
}

export default RatingComponent;
