import '../../style/login.css';
import React, { useState, useEffect } from 'react';
import { getData } from '../../backend/data';
import AvgRatingDisplay from '../home/AvgRatingDisplay';
import '../../style/App.css';
import { DNA, Grid } from 'react-loader-spinner';



function OverallRanking() {
    // State to store the fetched data
    const [data, setData] = useState([]);
    // State to track loading status
    const [isLoading, setIsLoading] = useState(true);
    // State to manage search query
    const [searchQuery, setSearchQuery] = useState('');

    // Fetch data from the API on component mount
    useEffect(() => {
        getData()
            .then(data => {
                const sortedData = data.sort((a, b) => (b.TOTAL / b.Counter) - (a.TOTAL / a.Counter));
                setData(sortedData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });

    }, []); // Empty dependency array means this effect runs once on mount

    // Filter data based on search query
    const filteredData = data.filter(item => item.Name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <div className="App">
            <h1 className='gradient-text page-header'>Overall Ranking</h1>
            <input
                type='text'
                placeholder='Search by name'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{marginBottom: '20px', padding: '10px', fontSize: '16px', width: '90%'}}
            />
            {isLoading ? (
                <div className='loading-container'>
                    <DNA height={'150'} width={'150'}/>
                    {/* <Grid /> */}
                </div>
            ) :
                <table style={{ fontFamily: 'Neue Regrade Semibold', color: 'wheat', width: '100%', fontSize: '14px' }}>
                    <thead style={{ fontFamily: 'Neue Regrade Bold' }}>
                        <tr>
                            <th style={{width: '10%'}}>Rang</th>
                            <th style={{width: '65%'}}>Name</th>
                            <th style={{width: '25%'}}>Avg Ranking</th>
                            {/* Add more columns as needed */}
                        </tr>
                    </thead>
                    <tbody >
                        {filteredData.map((item, index) => (
                            <tr key={index}>
                                <td className='table-row-padding'>{index + 1}</td>
                                <td className='table-row-padding' style={{ fontSize: '15px' }}>{item.Name}</td>
                                <td className='table-row-padding'>
                                    <AvgRatingDisplay value={item.TOTAL / item.Counter} precision={0.1} size={'small'} counter={item.Counter} />
                                </td>
                                {/* Render more data as needed */}
                            </tr>
                        ))}
                    </tbody>
                </table>}
        </div>
    );
}

export default OverallRanking;