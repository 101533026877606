import React from 'react';
import './style/App.css';
import './style/style.css';
import './style/login.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/common/navbar';
import Home from './components/home/Home';
import oaRanking from './components/rankings/OverallRanking';
import persRanking from './components/rankings/PersonalRanking';
import imprint from './components/imprint/Imprint'
import Login from './components/login/Login';
import Settings from './components/settings/Settings';
import PasswordReset from './components/login/PasswordReset';
import PasswordResetForm from './components/login/PasswordResetForm';
import { AvatarProvider } from './components/common/AvatarContext';
import VerifyEmail from './components/login/VerifyEmail';
import ProtectedRoute from './components/common/ProtectedRoute';
import PrivacyPolicy from './components/privacy_policy/PrivacyPolicy';
import Footer from './components/common/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <AvatarProvider>
          <Navbar />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/oaRanking" component={oaRanking} />
            <Route path="/persRanking" component={persRanking} />
            <Route path="/kontakt" component={imprint} />
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/settings" component={Settings} />
            <Route path="/reset-password/:token" component={PasswordResetForm} /> {/* has to come before the basic url to be able to check for a token */}
            <Route path="/reset-password" component={PasswordReset} />
            <Route path="/confirm-email/:token" component={VerifyEmail} /> {/* TODO change url */}
            <Route path="/datenschutzerklaerung" component={PrivacyPolicy} />
          </Switch>
        </AvatarProvider>
        <Footer />
      </div>
    </Router>
  );
}

export default App;