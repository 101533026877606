import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { verifyUser } from "../../backend/data";
import { logIn } from "./LoginUtils";
import { AvatarContext } from "../common/AvatarContext";
import { Rating } from "@mui/material";

function VerifyEmail() {
  const { token } = useParams();
  const { avatarState, setAvatarState } = useContext(AvatarContext);

  const verifyEmailOnLoad = () => {
    verifyUser(token);
    console.log("avatarState.userEmail: ", localStorage.getItem("userEmail"));
    logIn(localStorage.getItem("userEmail"), setAvatarState);
    setAvatarState({
      loggedIn: true,
      userEmail: localStorage.getItem("userEmail"),
      avatarColor: avatarState.avatarColor,
    });
  };

  useEffect(() => {
    verifyEmailOnLoad();
  }, [token]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      className="App App-header blend-into-image background-blur"
    >
      <div
        style={{
          height: "35vh",
        }}
      >
        <form>
          <h2 className="">Deine E-Mail wurde verifiziert!</h2>
          <Rating name="verification-rating" defaultValue={5} readOnly/>
          <p>
            Herzlichen Glückwunsch, du kannst nun von dem
          </p>
          <p style={{marginTop: 0}}>Local Ranking profitieren!</p>
          <Link to="">
            <button type="button">Homepage</button>
          </Link>
        </form>
      </div>
    </div>
  );
}

export default VerifyEmail;
