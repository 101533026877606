import React from "react";
import { Google, GitHub, Apple, VisibilityOff, Visibility } from "@mui/icons-material";
import { handleSignUpSubmit } from "./LoginUtils";

function SignUpForm() {
  const [state, setState] = React.useState({
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className="form-container sign-up-container">
      <form onSubmit={(evt) => handleSignUpSubmit(evt, state, setState)}>
        <h1>Create Account</h1>
        {/* <div className="social-container">
          <a href="#" className="social">
            <Google />
          </a>
          <a href="#" className="social">
            <Apple />
          </a>
          <a href="#" className="social">
            <GitHub />
          </a>
        </div>
        <span className="sign">or use your email for registration</span> */}

        <input
          type="email"
          name="email"
          value={state.email}
          onChange={handleChange}
          placeholder="Email"
          autoComplete="username"
        />

        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={state.password}
            onChange={handleChange}
            placeholder="Password"
            autoComplete="new-password"
          />
          <span className="toggle-icon sign" onClick={toggleShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </span>
        </div>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            value={state.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm password"
            autoComplete="new-password"
          />
          <span className="toggle-icon sign" onClick={toggleShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </span>
        </div>
        <button style={{ marginTop: "20px" }}>Sign Up</button>
      </form >
    </div >
  );
}

export default SignUpForm;
