import React, { useEffect, useState } from 'react';

const ScrollDownOnLoad = ({ cookiesAccepted, headingRef}) => {
    const [delayedScroll, setDelayedScroll] = useState(false);

    useEffect(() => {
        if (cookiesAccepted) {
            const timeout = setTimeout(() => {
                setDelayedScroll(true);
            }, 750);

            return () => clearTimeout(timeout);
        }
    }, [cookiesAccepted]);

    useEffect(() => {
        // Scroll the page down on load
        if (delayedScroll && headingRef.current) {
            headingRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [delayedScroll, headingRef]); // Empty dependency array ensures this effect runs only once after the component mounts

    return (
        <div className={delayedScroll ? 'scroll-down-animation' : ''}>
        </div>
    );
};

export default ScrollDownOnLoad;
