import React from "react";
import { Google, GitHub, Apple, Visibility, VisibilityOff } from "@mui/icons-material";
import { handleSignUpSubmit } from "./LoginUtils";

function MobileSignUp({ onSwitch }) {
    const [state, setState] = React.useState({
        email: "",
        password: "",
        confirmPassword: ""
    });
    const [showPassword, setShowPassword] = React.useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleChange = evt => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    return (
        <div >
            <form onSubmit={(evt) => handleSignUpSubmit(evt, state, setState)}>
                <h1>Create Account</h1>
                {/* <div className="social-container">
                    <a href="#" className="social">
                        <Google />
                    </a>
                    <a href="#" className="social">
                        <Apple />
                    </a>
                    <a href="#" className="social">
                        <GitHub />
                    </a>
                </div>
                <span className="sign">or use your email for registration</span> */}
                <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    autoComplete="username"
                    value={state.email}
                    onChange={handleChange}
                />
                <div className="password-container">
                    <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        value={state.password}
                        onChange={handleChange}
                    />
                    <span className="toggle-icon sign" onClick={toggleShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </span>
                </div>
                <div className="password-container">
                    <input
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm password"
                        autoComplete="new-password"
                        value={state.confirmPassword}
                        onChange={handleChange}
                    />
                    <span className="toggle-icon sign" onClick={toggleShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </span>
                </div>
                <button style={{marginTop: "10px"}}>Sign Up</button>
                <p>
                    Already have an account? <a onClick={onSwitch}>Sign In</a>
                </p>
            </form>
        </div>
    );
}

export default MobileSignUp;